
                    @import "@/css/ajax-rendering/admin-lte/_variables.scss";
                
$popupColor: #ed6a56;
$tableHeaderColor: #f7f7f7 ;

#backorder-popup-parent div.popover {
    max-width: 420px;
    min-width: 300px;
    & h3.popover-title{
        background-color:$popupColor;
        font-weight:bold;
    }
}

.backorders {
    max-height: 50vh;
    overflow-y: auto;

    & th {
        padding: 5px;
        background-color: $tableHeaderColor;
        font-weight:normal;
    }

    & td {
        padding: 5px;
        padding-bottom: 0px;
    }
}

a.backorder-info i.fa-exclamation {
    background-color: $popupColor;
    border-radius:50%;
    width: 30px;
    height: 30px;
    padding-top: 8px;
    color: black;
    text-align:center;
}

a.backorder-info {
    background-color: black;
    display: inline-block;
    height: 26px;
    width: 23px;
    border-radius: 50%;
}

a.backorder-info i.glyphicon-exclamation-sign {
    background-color: transparent;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    top:-2px;
    position:relative;
    display:inline-block;
    left:-4px;

    &::before {
        background-color: transparent;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-top: 8px;
        color: $popupColor;
        text-align: center;
        border-color: transparent;
        font-size: 30px;
    }
}